import React from 'react'
import LazyLoad from 'react-lazyload'
import styles from './styles.module.css'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'
import {pad} from '../utils'

const images = {
  total: 500,
  width: 508,
  height: 800
}

const content =

<React.Fragment>
{/* Content starts here */}

  {new Array(images.total).fill(undefined).map((val,i) => pad(i+1, 3)).map((img, i) => {
    return (
      <LazyLoad
        offset={500}
        width={images.width}
        height={images.height}
        placeholder={<Placeholder className={styles.placeholder} width={images.width} height={images.height} />}
        key={`${i+1}`}
        once={true}
      >
        <img
          key={`${i+1}`}
          className={styles.image}
          alt=""
          width={images.width}
          height={images.height}
          src={`${process.env.PUBLIC_URL}/project-media/DrowningLanguageI/drowning-language-i-${img}-guillermo-gineste.jpeg`}
        />
      </LazyLoad>
    )
  })}

{/* Content ends */}
</React.Fragment>


export default content;
