import React, { Component } from 'react';
import styles from './TheArt.module.css';

class TheArt extends Component {
  componentDidMount() {
    window.scrollTo(0,0); 
  }
  render() {
    return (
      <div className={`${styles.theArt} ${this.props.className}`}>
        {this.props.content}
      </div>
    );
  }
}

export default TheArt;