import React from 'react'
import LazyLoad from 'react-lazyload'
import styles from './styles.module.css'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'
import {pad} from '../utils'
import titles from './titles'

const images = {
  total: 100,
  width: 1131,
  height: 800
}



const content =

<React.Fragment>
{/* Content starts here */}

  {new Array(images.total).fill(undefined).map((val,i) => pad(i+1, 3)).map((img, i) => {
    const currentTitle = titles[i]
    return (
      <React.Fragment key={`f${i+1}`}>
        <LazyLoad
          offset={500}
          width={images.width}
          height={images.height}
          placeholder={<Placeholder className={styles.placeholder} width={images.width} height={images.height} />}
          key={`${i+1}`}
          once={true}
        >
          <img
            key={`${i+1}`}
            className={styles.image}
            alt=""
            width={images.width}
            height={images.height}
            src={`${process.env.PUBLIC_URL}/project-media/DrowningLanguageVi/drowning-language-vi-${img}-guillermo-gineste.jpeg`}
          />
        </LazyLoad>
        <span className={styles.title}><p>{currentTitle}</p></span>
      </React.Fragment>
    )
  })}

{/* Content ends */}
</React.Fragment>


export default content;
