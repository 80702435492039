import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>Each part of this video has two layers. The background layer is an 
abstraction of different recordings of flowing water. A one pixel 
horizontal line is extracted from the original video and repeated 
several times, this group of lines is stretched to fill the height 
of the frame. A composition of various overlapping loops forms the 
second layer. The loops come from classic animated films from the 
30s and 40s, the beginning of the golden age of american animation.</p>
<p>The superposition of layers creates an opposition of a meditative 
image, the abstracted water flowing, and the fast and silly feeling 
of the animated graphic elements.</p><p>On each of the three parts 
the stretched pixel lines are offset in time a few frames, this delay 
creates a soft cascading movement. In the first part, the offset 
gives the illusion of ascension. In part II the offset is vertically 
symmetric, giving no specific movement illusion. In the last part the 
offset gives the impression of descending movement.</p>
<p>Under the video, all its parts are displayed — Frames from the 
original water videos and every animated loop with the number of 
frames it lasts.</p>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;