import tinycolor from 'tinycolor2'
import {getRandomInt, getLum, randomAngleExcludingOffset} from './utils';

//TODO: Function that takes an array of colors and matches their luminosities
// const matchLum = () => {

// };

const createInitialColor = (initialColor) => {
  const randomP1 = tinycolor({
    h: getRandomInt(0, 360),
    s: getRandomInt(5, 95),
    l: getRandomInt(15, 95)
  })
  return initialColor ? tinycolor(initialColor) : randomP1;
};

const setPrimaries = (p1color, p2color, p3color) => {
  const p1lum = getLum(p1color);
  const p2lum = getLum(p2color);
  const p3lum = getLum(p3color);
  // const p1lum = p1color.getBrightness();
  // const p2lum = p2color.getBrightness();
  // const p3lum = p3color.getBrightness();

  return {
    p1: {
      mainColor: [
        p1color,
        tinycolor({ r: p1lum, g: p1lum, b: p1lum })
      ],
    },
    p2: {
      mainColor: [
        p2color,
        tinycolor({ r: p2lum, g: p2lum, b: p2lum })
      ],
    },
    p3: {
      mainColor: [
        p3color,
        tinycolor({ r: p3lum, g: p3lum, b: p3lum })
      ],
    },
  }
};

export const createPrimariesWithNone = () => {
  let p1color = tinycolor('#1f4ed2');
  let p2color = tinycolor('#bc1f49');
  let p3color = tinycolor('#fffd00');

  return setPrimaries(p1color, p2color, p3color);
};

export const createPrimariesWithAnyThree = (initialColor) => {
  // 1. Create first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor);
  const p1Hue = p1color.toHsl().h;
  // 2. Create second primary.
  const p2Hue = randomAngleExcludingOffset({
    inRange: 360, 
    removeAngle: [p1Hue], 
    withOffset: 15, 
    removeRollover: true
  })
  const p2color = tinycolor({ h: p2Hue, s: getRandomInt(5, 95), l: getRandomInt(15, 90) })
  // 3. Create third primary.
  const p3Hue = randomAngleExcludingOffset({
    inRange: 360, 
    removeAngle: [p1Hue, p2Hue], 
    withOffset: 10, 
    removeRollover: true
  })
  const p3color = tinycolor({ h: p3Hue, s: getRandomInt(5, 95), l: getRandomInt(15, 90)})

  return setPrimaries(p1color, p2color, p3color);
};

export const createPrimariesAsTriad = (initialColor) => {
  // 1. Create first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor);
  // 2. Create second primary.
  const p1Hue = p1color.toHsl().h;
  const p2Hue = (p1Hue + 120 + getRandomInt(-10, 10)) % 360;
  const p2color = tinycolor({
    h: p2Hue, s: getRandomInt(5, 90), l: getRandomInt(15, 90)
  })
  // 3. Create third primary.
  const p3Hue = p2Hue + 120 + getRandomInt(-10, 10) % 360;
  const p3color = tinycolor({
    h: p3Hue, s: getRandomInt(5, 90), l: getRandomInt(15, 90)
  })
  return setPrimaries(p1color, p2color, p3color);
};

export const createPrimariesAsAnalogous = (initialColor) => {
  // 1. Create first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor);
  // 2. Create second primary.
  const p1Hue = p1color.toHsl().h;
  const p2Hue = (p1Hue  + 30 + getRandomInt(-5, 5)) % 360;
  const p2color = tinycolor({
    h: p2Hue,
    s: getRandomInt(5, 90),
    l: getRandomInt(15, 90)
  })
  // 3. Create third primary.
  const p3Hue = (p2Hue + 30 + getRandomInt(-5, 5)) % 360;
  const p3color = tinycolor({ h: p3Hue, s: getRandomInt(5, 90), l: getRandomInt(15, 90) })
  return setPrimaries(p1color, p2color, p3color);
};

export const createPrimariesAsComplementariesAndAccent = (initialColor) => {
  // 1. Greate first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor);
  // 2. Create second primary.
  const p1Hue = p1color.toHsl().h;
  const p2Hue = (p1Hue  + 180 + getRandomInt(-15, 15)) % 360;
  const p2color = tinycolor({
    h: p2Hue, s: getRandomInt(5, 90), l: getRandomInt(5, 90)
  })
  // 3. Create third primary.
  const p3Hue = randomAngleExcludingOffset({
    inRange: 360, 
    removeAngle: [p1Hue, p2Hue], 
    withOffset: 35, 
    removeRollover: true
  })
  const p3color = tinycolor({ h: p3Hue, s: getRandomInt(5, 90), l: getRandomInt(15, 90)
  })
  return setPrimaries(p1color, p2color, p3color);
}