import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

  <p>A man died in the street where I was living. They put white powder to dry the blood so it doesn’t leave a mark on the sidewalk.
  <br></br>A few days before, I started carrying a cheap digital camera, I took pictures of anything that cought my eye with no theme or criteria.
  <br></br>A note in spanish appeared, and some flowers.
  <br></br>I took a photo of the place where the man died every time I passed by it.
  <br></br>After some time, the note, the flowers, the blood and the white powder disappeared.
  <br></br>This are all the images I took during that time.
  </p>
  <small>Berlin — 2013-2014</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;