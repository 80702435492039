import React, { Component } from 'react';
import styles from './Explanation.module.css';

class Explanation extends Component {
  state = {
    textIsHidden: false,
  }
  handleToggleText = () => {
    this.setState((prevState) => ({ 
      textIsHidden: !prevState.textIsHidden,
    }));
  }
  render() {
    const {
      parent,
      name
    } = this.props
    return (
      <div 
        className={
          `${styles.explanation} ${this.state.textIsHidden && styles.explanationIsHidden}`
        }
      >
        <button 
          className={styles.collapseButton} 
          onClick={this.handleToggleText}
        >
          {this.state.textIsHidden ? "← Show text" : "Close →"}
        </button>
        {
          !this.state.textIsHidden &&
          <div className={styles.explanationContent}>
            {
              parent && 
              <h3 className={styles.projectParent}>
                {parent}...
              </h3>
            }
            <h2 className={styles.projectTitle}>{name}</h2>
            <div className={styles.projectText}>
              {this.props.children}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Explanation;