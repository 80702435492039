import React, { Component } from 'react';
import styles from './Word.module.css';

class Word extends Component {
  state = {
    isHover: false,
    imgX: 0,
    imgY: 0,
  }
  handleHover = () => {
    this.setState((prevState) => ({
      isHover: !prevState.isHover
    }));
  };
  handleMouseMoving = (e) => {
    const imageSize = 200; 

    // const imgX = e.nativeEvent.pageX;
    // const imgY = e.nativeEvent.pageY;
    const imgX = e.nativeEvent.clientX;
    const imgY = e.nativeEvent.clientY;
    
    // console.log(imgX, imgY);

    // console.log(this.refs.word.scrollTop);
    // console.log(e.nativeEvent.clientY);
    
    const distanceToRight = document.body.clientWidth - e.nativeEvent.clientX;
    const distanceToBottom = window.innerHeight - e.nativeEvent.clientY;
    
    const correctedX = distanceToRight - 5 < imageSize ? imgX + distanceToRight - 5 - imageSize : imgX;
    const correctedY = distanceToBottom - 5 < imageSize ? imgY + distanceToBottom - 5 - imageSize : imgY;
    
    this.setState(() => ({
      imgX: correctedX,
      imgY: correctedY + 12,
    }));
  }
  render() {

    return (
      <React.Fragment>
        <span
          onMouseEnter={this.handleHover} 
          onMouseLeave={this.handleHover}
          onMouseMove={this.handleMouseMoving}
          className={styles.word}
          ref="word"
        > 
        {this.props.children}
        </span>

        {this.state.isHover && this.props.img &&
          <img 
          src={this.props.img}
          alt=""
          style={{
            position: "fixed",
            left: this.state.imgX,
            top: this.state.imgY,
            zIndex: -1
          }}
        />
      }
      </React.Fragment>
    );
  }
}

export default Word;