import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import HomePage from '../projects/HomePage/HomePage';
import list from '../projects/ListOfProjects';

import DefaultLayout from './layouts/DefaultLayout';
import ProjectLayout from './layouts/ProjectLayout';

const AppRouter = () => {
  return (
    <BrowserRouter>
        <Switch>
          <DefaultLayout path="/" component={HomePage} exact={true}/>
          {list.map(project => {
            return (
              <ProjectLayout 
                key={project.path}
                path={project.path}
                component={project.component} 
                exact={true}
              />
            )
          })}
        </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;