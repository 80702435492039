const partitions = [
  [16],
  [15, 1],
  [14, 2],
  [14, 1, 1],
  [13, 3],
  [13, 2, 1],
  [13, 1, 1, 1],
  [12, 4],
  [12, 3, 1],
  [12, 2, 2],
  [12, 2, 1, 1],
  [12, 1, 1, 1, 1],
  [11, 5],
  [11, 4, 1],
  [11, 3, 2],
  [11, 3, 1, 1],
  [11, 2, 2, 1],
  [11, 2, 1, 1, 1],
  [11, 1, 1, 1, 1, 1],
  [10, 6],
  [10, 5, 1],
  [10, 4, 2],
  [10, 4, 1, 1],
  [10, 3, 3],
  [10, 3, 2, 1],
  [10, 3, 1, 1, 1],
  [10, 2, 2, 2],
  [10, 2, 2, 1, 1],
  [10, 2, 1, 1, 1, 1],
  [10, 1, 1, 1, 1, 1, 1],
  [9, 7],
  [9, 6, 1],
  [9, 5, 2],
  [9, 5, 1, 1],
  [9, 4, 3],
  [9, 4, 2, 1],
  [9, 4, 1, 1, 1],
  [9, 3, 3, 1],
  [9, 3, 2, 2],
  [9, 3, 2, 1, 1],
  [9, 3, 1, 1, 1, 1],
  [9, 2, 2, 2, 1],
  [9, 2, 2, 1, 1, 1],
  [9, 2, 1, 1, 1, 1, 1],
  [9, 1, 1, 1, 1, 1, 1, 1],
  [8, 8],
  [8, 7, 1],
  [8, 6, 2],
  [8, 6, 1, 1],
  [8, 5, 3],
  [8, 5, 2, 1],
  [8, 5, 1, 1, 1],
  [8, 4, 4],
  [8, 4, 3, 1],
  [8, 4, 2, 2],
  [8, 4, 2, 1, 1],
  [8, 4, 1, 1, 1, 1],
  [8, 3, 3, 2],
  [8, 3, 3, 1, 1],
  [8, 3, 2, 2, 1],
  [8, 3, 2, 1, 1, 1],
  [8, 3, 1, 1, 1, 1, 1],
  [8, 2, 2, 2, 2],
  [8, 2, 2, 2, 1, 1],
  [8, 2, 2, 1, 1, 1, 1],
  [8, 2, 1, 1, 1, 1, 1, 1],
  [8, 1, 1, 1, 1, 1, 1, 1, 1],
  [7, 7, 2],
  [7, 7, 1, 1],
  [7, 6, 3],
  [7, 6, 2, 1],
  [7, 6, 1, 1, 1],
  [7, 5, 4],
  [7, 5, 3, 1],
  [7, 5, 2, 2],
  [7, 5, 2, 1, 1],
  [7, 5, 1, 1, 1, 1],
  [7, 4, 4, 1],
  [7, 4, 3, 2],
  [7, 4, 3, 1, 1],
  [7, 4, 2, 2, 1],
  [7, 4, 2, 1, 1, 1],
  [7, 4, 1, 1, 1, 1, 1],
  [7, 3, 3, 3],
  [7, 3, 3, 2, 1],
  [7, 3, 3, 1, 1, 1],
  [7, 3, 2, 2, 2],
  [7, 3, 2, 2, 1, 1],
  [7, 3, 2, 1, 1, 1, 1],
  [7, 3, 1, 1, 1, 1, 1, 1],
  [7, 2, 2, 2, 2, 1],
  [7, 2, 2, 2, 1, 1, 1],
  [7, 2, 2, 1, 1, 1, 1, 1],
  [7, 2, 1, 1, 1, 1, 1, 1, 1],
  [7, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [6, 6, 4],
  [6, 6, 3, 1],
  [6, 6, 2, 2],
  [6, 6, 2, 1, 1],
  [6, 6, 1, 1, 1, 1],
  [6, 5, 5],
  [6, 5, 4, 1],
  [6, 5, 3, 2],
  [6, 5, 3, 1, 1],
  [6, 5, 2, 2, 1],
  [6, 5, 2, 1, 1, 1],
  [6, 5, 1, 1, 1, 1, 1],
  [6, 4, 4, 2],
  [6, 4, 4, 1, 1],
  [6, 4, 3, 3],
  [6, 4, 3, 2, 1],
  [6, 4, 3, 1, 1, 1],
  [6, 4, 2, 2, 2],
  [6, 4, 2, 2, 1, 1],
  [6, 4, 2, 1, 1, 1, 1],
  [6, 4, 1, 1, 1, 1, 1, 1],
  [6, 3, 3, 3, 1],
  [6, 3, 3, 2, 2],
  [6, 3, 3, 2, 1, 1],
  [6, 3, 3, 1, 1, 1, 1],
  [6, 3, 2, 2, 2, 1],
  [6, 3, 2, 2, 1, 1, 1],
  [6, 3, 2, 1, 1, 1, 1, 1],
  [6, 3, 1, 1, 1, 1, 1, 1, 1],
  [6, 2, 2, 2, 2, 2],
  [6, 2, 2, 2, 2, 1, 1],
  [6, 2, 2, 2, 1, 1, 1, 1],
  [6, 2, 2, 1, 1, 1, 1, 1, 1],
  [6, 2, 1, 1, 1, 1, 1, 1, 1, 1],
  [6, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [5, 5, 5, 1],
  [5, 5, 4, 2],
  [5, 5, 4, 1, 1],
  [5, 5, 3, 3],
  [5, 5, 3, 2, 1],
  [5, 5, 3, 1, 1, 1],
  [5, 5, 2, 2, 2],
  [5, 5, 2, 2, 1, 1],
  [5, 5, 2, 1, 1, 1, 1],
  [5, 5, 1, 1, 1, 1, 1, 1],
  [5, 4, 4, 3],
  [5, 4, 4, 2, 1],
  [5, 4, 4, 1, 1, 1],
  [5, 4, 3, 3, 1],
  [5, 4, 3, 2, 2],
  [5, 4, 3, 2, 1, 1],
  [5, 4, 3, 1, 1, 1, 1],
  [5, 4, 2, 2, 2, 1],
  [5, 4, 2, 2, 1, 1, 1],
  [5, 4, 2, 1, 1, 1, 1, 1],
  [5, 4, 1, 1, 1, 1, 1, 1, 1],
  [5, 3, 3, 3, 2],
  [5, 3, 3, 3, 1, 1],
  [5, 3, 3, 2, 2, 1],
  [5, 3, 3, 2, 1, 1, 1],
  [5, 3, 3, 1, 1, 1, 1, 1],
  [5, 3, 2, 2, 2, 2],
  [5, 3, 2, 2, 2, 1, 1],
  [5, 3, 2, 2, 1, 1, 1, 1],
  [5, 3, 2, 1, 1, 1, 1, 1, 1],
  [5, 3, 1, 1, 1, 1, 1, 1, 1, 1],
  [5, 2, 2, 2, 2, 2, 1],
  [5, 2, 2, 2, 2, 1, 1, 1],
  [5, 2, 2, 2, 1, 1, 1, 1, 1],
  [5, 2, 2, 1, 1, 1, 1, 1, 1, 1],
  [5, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [5, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [4, 4, 4, 4],
  [4, 4, 4, 3, 1],
  [4, 4, 4, 2, 2],
  [4, 4, 4, 2, 1, 1],
  [4, 4, 4, 1, 1, 1, 1],
  [4, 4, 3, 3, 2],
  [4, 4, 3, 3, 1, 1],
  [4, 4, 3, 2, 2, 1],
  [4, 4, 3, 2, 1, 1, 1],
  [4, 4, 3, 1, 1, 1, 1, 1],
  [4, 4, 2, 2, 2, 2],
  [4, 4, 2, 2, 2, 1, 1],
  [4, 4, 2, 2, 1, 1, 1, 1],
  [4, 4, 2, 1, 1, 1, 1, 1, 1],
  [4, 4, 1, 1, 1, 1, 1, 1, 1, 1],
  [4, 3, 3, 3, 3],
  [4, 3, 3, 3, 2, 1],
  [4, 3, 3, 3, 1, 1, 1],
  [4, 3, 3, 2, 2, 2],
  [4, 3, 3, 2, 2, 1, 1],
  [4, 3, 3, 2, 1, 1, 1, 1],
  [4, 3, 3, 1, 1, 1, 1, 1, 1],
  [4, 3, 2, 2, 2, 2, 1],
  [4, 3, 2, 2, 2, 1, 1, 1],
  [4, 3, 2, 2, 1, 1, 1, 1, 1],
  [4, 3, 2, 1, 1, 1, 1, 1, 1, 1],
  [4, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [4, 2, 2, 2, 2, 2, 2],
  [4, 2, 2, 2, 2, 2, 1, 1],
  [4, 2, 2, 2, 2, 1, 1, 1, 1],
  [4, 2, 2, 2, 1, 1, 1, 1, 1, 1],
  [4, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1],
  [4, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [4, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [3, 3, 3, 3, 3, 1],
  [3, 3, 3, 3, 2, 2],
  [3, 3, 3, 3, 2, 1, 1],
  [3, 3, 3, 3, 1, 1, 1, 1],
  [3, 3, 3, 2, 2, 2, 1],
  [3, 3, 3, 2, 2, 1, 1, 1],
  [3, 3, 3, 2, 1, 1, 1, 1, 1],
  [3, 3, 3, 1, 1, 1, 1, 1, 1, 1],
  [3, 3, 2, 2, 2, 2, 2],
  [3, 3, 2, 2, 2, 2, 1, 1],
  [3, 3, 2, 2, 2, 1, 1, 1, 1],
  [3, 3, 2, 2, 1, 1, 1, 1, 1, 1],
  [3, 3, 2, 1, 1, 1, 1, 1, 1, 1, 1],
  [3, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [3, 2, 2, 2, 2, 2, 2, 1],
  [3, 2, 2, 2, 2, 2, 1, 1, 1],
  [3, 2, 2, 2, 2, 1, 1, 1, 1, 1],
  [3, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1],
  [3, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 2, 2, 2],
  [2, 2, 2, 2, 2, 2, 2, 1, 1],
  [2, 2, 2, 2, 2, 2, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
]

export default partitions