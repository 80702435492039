import React, { Component } from 'react';
import styles from './ColorScale.module.css';
// import tinycolor from 'tinycolor2';
import ColorScaleGenerator from '../../scale-generator/ColorScale';

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // eslint-disable-line no-param-reassign
  }
  return array;
};

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
};

const generateSetOfRandomPercentages = ({
  segments = 6,
  max = 300,
  segmentMax = 300
} = {}) => {
  let remaining = max;
  return Array.from({ length: segments }, (value, index, array) => {
    const newValue =
      index === segments - 1 ? remaining : getRandomInt(10, segmentMax - 25);

    remaining = remaining - newValue;
    segmentMax = remaining;

    return newValue;
  });
};

class ColorScale extends Component {
  state = {
    scale: new ColorScaleGenerator({ method: "randomMethod" })
  };

  render() {
    const fullColorSet = this.state.scale.createFullColorSet();
    const primaries = [
      fullColorSet.p1.mainColor[0].toHexString(),
      fullColorSet.p2.mainColor[0].toHexString(),
      fullColorSet.p3.mainColor[0].toHexString(),
      fullColorSet.s1.mainColor[0].toHexString(),
      fullColorSet.s2.mainColor[0].toHexString(),
      fullColorSet.s3.mainColor[0].toHexString()
    ];

    const proportionArray = shuffleArray(generateSetOfRandomPercentages({}));
    return (
      <div className={styles.scale} style={{width: this.props.width}}>
      {proportionArray.map((value, i) => {
        return (
          <div 
            key={i} 
            className={styles.color}
            style={{ 
              height: value,
              background: primaries[i]
            }}
          />
        )
      })}
      </div>
    );
  }
}

export default ColorScale;