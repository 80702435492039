import tinycolor from 'tinycolor2';

import {getRandomInt, getLum} from './utils';
import {
  createPrimariesWithNone, 
  createPrimariesWithAnyThree, 
  createPrimariesAsTriad,
  createPrimariesAsAnalogous,
  createPrimariesAsComplementariesAndAccent
} from './methods';

export default class ColorScale {
  /*
    (0) 'none' ...................... Default three colors
    (1) 'randomMethod' .............. Will select a random method from the list
    (2) 'anyThree' .................. Random three colors
    (3) 'triad' ..................... Triad of colors
    (4) 'analogous' ................. Three close colors
    (5) 'complementariesAndAccent' .. Two complementary and an accent
  */
  constructor({method = 'none', initialColor}) {
    this.method = method;
    this.initialColor = initialColor;    
    // Array with all methods
    const methods = ['none', 'randomMethod', 'anyThree', 'triad', 'analogous', 'complementariesAndAccent'];
    // Find indext of method
    let methodIndex = methods.findIndex(method => method === this.method);
    this.methodIndex = methodIndex < 0 ? 0 : methodIndex;
    this.fullColorSet = {};
  }

  createPrimaries = () => {
    let primaries;
    const methodToUse = this.methodIndex === 1 ? getRandomInt(2,5) : this.methodIndex;

    switch (methodToUse) {
      case 2: /* anyThree */
        // console.log('Any Three');
        primaries = createPrimariesWithAnyThree(this.initialColor);
        break;
      case 3: /* triad */
        // console.log('Triad');
        primaries = createPrimariesAsTriad(this.initialColor);
        break;
      case 4: /* analogous */
        // console.log('Analogous');
        primaries = createPrimariesAsAnalogous(this.initialColor);
        break;
      case 5: /* compAndAccent */
        // console.log('Complementaries And Accent');
        primaries = createPrimariesAsComplementariesAndAccent(this.initialColor);
        break;
      default: /* None or Undefined */
      // console.log('None or Undefined');
        primaries = createPrimariesWithNone();
        break;
    }
    return primaries;
  }

  createSecondaries = (p1color, p2color, p3color) => {
    const s1color = tinycolor.mix(p1color, p2color, 50)
    const s2color = tinycolor.mix(p2color, p3color, 50)
    const s3color = tinycolor.mix(p3color, p1color, 50)

    const s1lum = getLum(s1color);
    const s2lum = getLum(s2color);
    const s3lum = getLum(s3color);

    return {
      s1: {
        mainColor: [
          s1color,
          tinycolor({ r: s1lum, g: s1lum, b: s1lum })
        ],
      },
      s2: {
        mainColor: [
          s2color,
          tinycolor({ r: s2lum, g: s2lum, b: s2lum })
        ],
      },
      s3: {
        mainColor: [
          s3color,
          tinycolor({ r: s3lum, g: s3lum, b: s3lum })
        ],
      },
    };
  }

  mergePrimariesAndSecondaries = (primaries, secondaries) => ({...primaries, ...secondaries});
  
  createDominantsForColor = (primariesAndSecondaries, color, key) => {

    const primary1 = primariesAndSecondaries.p1.mainColor[0];
    const primary2 = primariesAndSecondaries.p2.mainColor[0];
    const primary3 = primariesAndSecondaries.p3.mainColor[0];
    const secondary1 = primariesAndSecondaries.s1.mainColor[0];
    const secondary2 = primariesAndSecondaries.s2.mainColor[0];
    const secondary3 = primariesAndSecondaries.s3.mainColor[0];

    const p1dom = tinycolor.mix(color, primary1, 50);
    const p2dom = tinycolor.mix(color, primary2, 50);
    const p3dom = tinycolor.mix(color, primary3, 50);
    const s1dom = tinycolor.mix(color, secondary1, 50);
    const s2dom = tinycolor.mix(color, secondary2, 50);
    const s3dom = tinycolor.mix(color, secondary3, 50);

    const grayColor = (color) => (tinycolor({ r: getLum(color), g: getLum(color), b: getLum(color) }))

    const genericDominantSet = {
      color: {
        p1dom: [ p1dom, grayColor(p1dom) ],
        p2dom: [ p2dom, grayColor(p2dom) ],
        p3dom: [ p3dom, grayColor(p3dom) ],
        s1dom: [ s1dom, grayColor(s1dom) ],
        s2dom: [ s2dom, grayColor(s2dom) ],
        s3dom: [ s3dom, grayColor(s3dom) ],
      }
    };
    
    let correctKeyDominantSet;

    switch (key) {
      case "p1":
        const { color: p1 } = {...genericDominantSet}
        correctKeyDominantSet = Object.assign( {}, {p1} );
        break;
      case "p2":
        const { color: p2 } = {...genericDominantSet}
        correctKeyDominantSet = Object.assign( {}, {p2} );
        break;
      case "p3":
        const { color: p3 } = {...genericDominantSet}
        correctKeyDominantSet = Object.assign( {}, {p3} );
        break;
      case "s1":
        const { color: s1 } = {...genericDominantSet}
        correctKeyDominantSet = Object.assign( {}, {s1} );
        break;
      case "s2":
        const { color: s2 } = {...genericDominantSet}
        correctKeyDominantSet = Object.assign( {}, {s2} );
        break;
      case "s3":
        const { color: s3 } = {...genericDominantSet}
        correctKeyDominantSet = Object.assign( {}, {s3} );
        break;
      default:
        break;
    }

    return correctKeyDominantSet;
  };

  createFullColorSet = () => {
    const primaries = this.createPrimaries();
    const secondaries = this.createSecondaries(
      primaries.p1.mainColor[0],
      primaries.p2.mainColor[0],
      primaries.p3.mainColor[0],
    );

    const primariesAndSecondaries = this.mergePrimariesAndSecondaries(
      primaries,
      secondaries
    );

    const p1DomScale = this.createDominantsForColor(
      primariesAndSecondaries, 
      primariesAndSecondaries.p1.mainColor[0],
      'p1'
    );
    const p2DomScale = this.createDominantsForColor(
      primariesAndSecondaries, 
      primariesAndSecondaries.p2.mainColor[0],
      'p2'
    );
    const p3DomScale = this.createDominantsForColor(
      primariesAndSecondaries, 
      primariesAndSecondaries.p3.mainColor[0],
      'p3'
    );
    const s1DomScale = this.createDominantsForColor(
      primariesAndSecondaries, 
      primariesAndSecondaries.s1.mainColor[0],
      's1'
    );
    const s2DomScale = this.createDominantsForColor(
      primariesAndSecondaries, 
      primariesAndSecondaries.s2.mainColor[0],
      's2'
    );
    const s3DomScale = this.createDominantsForColor(
      primariesAndSecondaries, 
      primariesAndSecondaries.s3.mainColor[0],
      's3'
    );


    const fullP1 = {};
    Object.assign(fullP1, primariesAndSecondaries.p1, p1DomScale.p1);
    const fullP2 = {};
    Object.assign(fullP2, primariesAndSecondaries.p2, p2DomScale.p2);
    const fullP3 = {};
    Object.assign(fullP3, primariesAndSecondaries.p3, p3DomScale.p3);
    const fullS1 = {};
    Object.assign(fullS1, primariesAndSecondaries.s1, s1DomScale.s1);
    const fullS2 = {};
    Object.assign(fullS2, primariesAndSecondaries.s2, s2DomScale.s2);
    const fullS3 = {};
    Object.assign(fullS3, primariesAndSecondaries.s3, s3DomScale.s3);
    
    let fullColorSet = {};
    fullColorSet.p1 = fullP1;
    fullColorSet.p2 = fullP2;
    fullColorSet.p3 = fullP3;
    fullColorSet.s1 = fullS1;
    fullColorSet.s2 = fullS2;
    fullColorSet.s3 = fullS3;

    
    // Add array with all unique colors to fullColorSet
    // console.log(tinycolor.equals(fullColorSet.p1.mainColor[0], fullColorSet.p1.mainColor[1]));

    const setAllColors = () => {
      let allColorsArrayWithDuplicates = [];

      Object.keys(fullColorSet)
        .map(x => (
            Object
            .keys(fullColorSet[x])
            .map(y => (allColorsArrayWithDuplicates.push(fullColorSet[x][y][0].toHex())))
        ));

      // Remove all duplicates from array
      let uniqueColorsArray = [ ...new Set(allColorsArrayWithDuplicates) ];

      let allColorsArray = []
      for (let value of uniqueColorsArray) {
        allColorsArray.push([tinycolor(value), getLum(tinycolor(value))]);
      }
      function compareSecondColumn(a, b) {
        if (a[1] === b[1]) {
          return 0;
        }
        return (a[1] < b[1]) ? -1 : 1;
      }
    
      allColorsArray.sort(compareSecondColumn);

      return allColorsArray
    };

    fullColorSet.allColors = setAllColors();

    // console.log(fullColorSet.allColors);
    
    return fullColorSet;
  }
}