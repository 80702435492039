import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import styles from './ProjectTitle.module.css';
import Word from './Word/Word';
import { getRandomInt, pad } from '../../../../utils';

class ProjectTitle extends Component {
  render() {
    const {
      isParent,
      title,
      slug,
      component,
      // ...props
    } = this.props;

    if (isParent) {
      return <h4 className={`${styles.title} ${styles.isParent}`}>{title}</h4>
    }
    const newTitle = title
      .match(/\S+\s*/gi)
      .map((word, i) => {
        const imgURL = `${process.env.PUBLIC_URL}/project-thumbnails/${component}/th-${pad(i+1, 3)}.jpg`
        return <Word key={i} img={imgURL}>{word}</Word>
      });
    return (
      <h5 className={styles.title} style={{marginBottom: getRandomInt(8, 14)}}>
        <NavLink className={styles.link} activeClassName={styles.linkIsActive} to={`/${slug}`}>{newTitle}</NavLink>
      </h5>
    )
  }
}

export default ProjectTitle;