import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

  <p>Three years after leaving, I come back to Madrid for the second time. I record the light 
  in the studio in Hochstraße.</p>
  <p>Notes and pictures, it's summer. More notes in the flight back.</p>

  <small>Berlin and Madrid — 2016-2017</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;