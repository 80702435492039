import React from 'react';
import styles from './styles.module.css';

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
  }
  return a;
}

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
}

const qualityArray = Array(getRandomIntInclusive(500, 1000)).fill('quality is everything ')
const yesterdayArray = Array(getRandomIntInclusive(500, 1000)).fill('yesterday I did ')
const todayArray = Array(getRandomIntInclusive(500, 1000)).fill('today I will do ')

// let allTexts = [...qualityArray, ...yesterdayArray, ...todayArray];
let allTexts = [...qualityArray, ...yesterdayArray, ...todayArray];

let finalText = [...allTexts, ...qualityArray, ...yesterdayArray, ...todayArray]

shuffle(finalText)

const allFlatten = finalText.join(' ')

shuffle(allTexts)

const content =

<React.Fragment>
  <img className={styles.image} alt="" src="https://loremflickr.com/640/360"/>
  {allFlatten}
</React.Fragment>


export default content;

// {allTexts.map((value, i) => {
//   return <span key={i} style={{marginLeft: getRandomIntInclusive(0,10)}}>{value}</span>
//  })}
//  {yesterdayArray.map((value, i) => {
//    return <span key={i}>{value}</span>
//  })}
//  {allTexts.map((value, i) => {
//    return <span key={i} style={{marginLeft: getRandomIntInclusive(0,20)}}>{value}</span>
//  })}
//  {qualityArray.map((value, i) => {
//    return <span key={i}>{value}</span>
//  })}
//  {todayArray.map((value, i) => {
//    return <span key={i}>{value}</span>
//  })}
//  {allTexts.map((value, i) => {
//    return <span key={i} style={{marginLeft: getRandomIntInclusive(0,30)}}>{value}</span>
//  })}
//  {todayArray.map((value, i) => {
//    return <span key={i}>{value}</span>
//  })}
//  {allTexts.map((value, i) => {
//    return <span key={i}style={{marginLeft: getRandomIntInclusive(0,50)}}>{value}</span>
//  })}
//  {yesterdayArray.map((value, i) => {
//    return <span key={i}>{value}</span>
//  })}
//  {todayArray.map((value, i) => {
//    return <span key={i}>{value}</span>
//  })}
//  {allTexts.map((value, i) => {
//    return <span key={i} style={{marginLeft: getRandomIntInclusive(0,70)}}>{value}</span>
//  })}