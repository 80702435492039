import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>you don’t remember<br></br>
following your trail,<br></br>
back<br></br>
along the water.<br></br>
under the trees.<br></br>
There is only looking<br></br>
and<br></br>
he will grow<br></br>
that green is too bright,<br></br>
and still there<br></br>
keep walking,<br></br>
back</p>

<p>two lines don’t converge<br></br>
the transition back,<br></br>
empty.<br></br>
forget.</p>

<p>replace that<br></br>
still,<br></br>
place of remains,<br></br>
light gray<br></br>
still attempting</p>

<p>back.<br></br>
they don’t remember,<br></br>
the naked mud and ashes</p>

<small>Berlin — 2014</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;