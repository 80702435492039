import React from 'react';
import styles from './styles.module.css';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>Two quiet ways to look: to a very small and silent world 
and very far, where the sound is out of reach.</p>

<table className={styles.explanationTable}>
  <tbody>
    <tr>
      <td>close</td>
      <td>far</td>
    </tr>
    <tr>
      <td>natural</td>
      <td>non natural</td>
    </tr>
    <tr>
      <td>outside</td> 
      <td>inside</td>
    </tr><tr>
      <td>structure</td>
      <td>...</td>
    </tr>
    <tr>
      <td>street sound</td>
      <td>nature sound</td>
    </tr>
    <tr>
      <td>ritual</td>
      <td>routine</td>
    </tr>
    <tr>
      <td>visual silence</td>
      <td>...</td>
    </tr>
    <tr>
      <td>still image</td>
      <td>moving image</td>
    </tr>
    <tr>
      <td>to watch</td>
      <td>to be watched</td>
    </tr>
  </tbody>
</table>

<p>The golden ratio as an example of imposing a 
pattern (meaning) on nature in our need for structure 
and understanding. 
The golden ratio is used to compose the video 
and image sets. It is also used in the editing of 
the video as a time scale.
</p>

<p>Each set of images is a sequence in a daily habit, 
a small ritual — cooking, dressing, etc. The 24 images on each set
are 1 second of movement (24fps). No movement, each frame a separated image.</p>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;