import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>Religion, mathematics and rational systems as ways of imposing order to chaos.</p>
<p>
<b>Form</b>
<br/>In the text fragment from Molloy, by Samuel Beckett, the main 
character is presented with the task of finding the best way to group 16 stones 
in his pockets so he can suck them one by one, all of them, without repetition.<br/>
A methodological way of understanding this problem is seeing it as a partitions 
problem. A partition is a sum of numbers that gives 16 as a result (15+1, 14+1+1, 14+2, etc...), 
there is 231 possible partitions for the number 16, which is to say, there is 
231 possible distributions of 16 individual elements.</p>
<p>
<b>Content</b>
<br/>All faces from a book of Bruegel’s paintings were scanned and cropped to a 4:6 
size, a common format for ID pictures. Some of this faces correspond to saints or 
holy figures from christianity, but most of them are common people. When cropped 
and out of context, the sanctity of the characters is lost. <br/>As a way of regaining 
order and control a saint is assigned on each partition or group of 16 images using a 
halo. Each saint character is determined by a cycle through the 16 elements — in the 
first group is the first element, in the second group the second one and so on until 
the last position is reached and the cycle starts again.<br/>All faces are distributed 
randomly each time this website is open, there is no fixed position for any of the 
faces. Only the form, the underlying structure, is constant. <br/><br/></p>
<p>Read a shorter version of the text <a href="http://www.samuel-beckett.net/molloy1.html">here</a>.<br/>
See a BBC representation <a href="https://www.youtube.com/watch?v=TXoq_H9BrTE">here</a>.<br/>
More about partitions <a href="https://www.youtube.com/watch?v=TXoq_H9BrTE">here</a> and <a href="https://www.artofproblemsolving.com/wiki/index.php?title=Partition_(combinatorics)">here</a>.<br/></p>



{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;