export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const stringToSlug = string => {
  string = string.trim().toLowerCase();

  // remove accents, swap ñ for n, etc
  const original = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaaaeeeeiiiioooouuuunc------";

  original.split("").map((char, i) => 
    string = string.replace(new RegExp(char, "g"), to.charAt(i))
  );

  string = string
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, ""); // trim - from end of text

  return string;
};

export const toPascalCase = (string) => {
  string = string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, (match) => {
    return match
      .charAt(match.length-1)
      .toUpperCase();
  });
  return string.charAt(0).toUpperCase() + string.substring(1);
}

export const pad = (number, widthToPad, paddingCharacter) => {
  paddingCharacter = paddingCharacter || '0';
  number = number + '';
  return number.length >= widthToPad ? number : new Array(widthToPad - number.length + 1).join(paddingCharacter) + number;
}