import React from 'react'
import LazyLoad from 'react-lazyload'
import styles from './styles.module.css'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'
import {pad} from '../utils'

const alphabet = [
  "a","b","c","d","e","f","g","h",
  "i","j","k","l","m","n","ni","o",
  "p","q","r","s","t","u","v","w",
  "x","y","z"
]

const images = {
  total: 351,
  perRow: 12,
  width: 500,
  height: 500
}

const Row = (props) => {
  const letter = props.letter;
  const rowFileName = `twenty-seven-paintings-${letter}`
  const arrayOfImages = new Array(images.perRow).fill(undefined).map((val,i) => {
    return `${rowFileName}${pad(i+1, 2)}`
  })
  return (
    <section className={styles.row} key={letter}>
      <LazyLoad
          offset={500}
          width={images.width}
          height={images.height}
          placeholder={<Placeholder className={styles.placeholder} width={images.width} height={images.height} />}
          once={true}
        >
          <img
            className={styles.image}
            alt="First color scale in series"
            width={images.width}
            height={images.height}
            src={`${process.env.PUBLIC_URL}/project-media/TwentySevenPaintings/${rowFileName}.jpg`}
          />
        </LazyLoad>
        {arrayOfImages.reverse().map((fileName, i) => {
          return (
            <LazyLoad
              offset={500}
              width={images.width}
              height={images.height}
              placeholder={<Placeholder className={styles.placeholder} width={images.width} height={images.height} />}
              once={true}
              key={`${i}-ll`}
            >
              <img
                className={styles.image}
                alt="First color scale in series"
                width={images.width}
                height={images.height}
                key={i}
                src={`${process.env.PUBLIC_URL}/project-media/TwentySevenPaintings/${fileName}.jpg`}
              />
            </LazyLoad>
          )
        })}
      </section>
  );
};

const content =

<React.Fragment>
{/* Content starts here */}

  {alphabet.map((letter, i) => {
    return (
      <Row letter={letter} key={`letter-${i}`}/>
    )
  })}

{/* Content ends */}
</React.Fragment>


export default content;
