import React from 'react';
import DefaultLayout from './DefaultLayout';

const ProjectLayout = ({component: Component, ...rest}) => {
  return (
    <DefaultLayout {...rest} component={matchProps => (
      <React.Fragment>
        <Component {...matchProps} />
      </React.Fragment>
    )} />
  );
};

export default ProjectLayout;