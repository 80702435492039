import React, { Component } from 'react';
import styles from './ImagePlaceholder.module.css';

class ImagePlaceholder extends Component {
  render() {
    return (
      <div
        style={{
          height: this.props.height,
          width: this.props.width
        }}
        className={`${styles.placeholder} ${this.props.className}`}
      >
      ...
      </div>
    );
  }
}

export default ImagePlaceholder;
