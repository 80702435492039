import React, { Component } from 'react'
import Header from './Header/Header'
import Intro from '../Intro/Intro'
import styles from './Overlay.module.css'
import List from '../MainNavigation/List/List'
import ColorScale from '../ColorScale/ColorScale'

// const other = [
//   {
//     name: "Color Scale Generator",
//     url: "http://guillermogineste.com/color"
//   }
// ]

class Overlay extends Component {
  render() {
    const {
      headerIsHidden,
      introIsHidden,
      navIsOpen,
      handleToggleNavigation,
      nextProject
    } = this.props
    return (
      <section id="overlay" className={`${styles.overlay} ${!navIsOpen && styles.overlayIsClosed}`}>
        <div className={styles.overlayContent}>
          {!headerIsHidden && 
            <Header
              handleToggleNavigation={handleToggleNavigation}
              navIsOpen={navIsOpen}
              nextProject={nextProject}
            />
          }
          {navIsOpen &&
              <div className={styles.list}>
              {!introIsHidden && 
                <Intro />
              }
              <List title="Projects" category="Projects"/>
              {/* <List title="Other things I do" category="Other" list={other}/> */}
              <ColorScale width={20} />
            </div>
          }
          
        </div>
      </section>
    )
  }
}

export default Overlay