import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

  <p>Right there, behind you.</p>
  <p>Just drew a line and found my way. Not really, neither did he but that's fine, I wasn't really there.</p>
  <small>Berlin — 2014</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;