import React from 'react';
import styles from './styles.module.css'

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>
<b>Vase of Flowers</b><br/> 
HEEM, Jan Davidsz. de, c. 1645<br/>
<b>Portrait of Jane Seymour, Queen of England</b><br/> 
HOLBEIN, Hans the Younger, c. 1536-1537<br/>
<b>The Last Judgment</b><br/> 
MEMLING, Hans, 1466-1473</p>
<div className={styles.words}>
  <span>Nature</span>
  <span>Artefacts</span>
  <span>Hell</span>
  <span>Heaven</span>
</div>
<p>Photographs of black and white prints of fragments from european 
Renaissance Paintings. This work is about representation. 
Representation of an idea (Hell - Heaven), of desire (Artefacts), 
of surroundings (Nature). In some of the prints the images overlap. 
In others, I have repainted with different levels of fidelity what is 
represented in the original painting. A realistic representation, abstract 
strokes or flat surfaces. </p>
<p>A flow of images representing themselves.</p>

<small>Berlin — 2013</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;