import React from 'react'
import styles from './styles.module.css'
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'

const content =

<React.Fragment>
{/* Content starts here */}

<div className={styles.videoContainer}>
  <VimeoVideo
    className={styles.video}
    title="Naked in Paradise"
    id="156110171"
    width="960"
    height="540"
  />
</div>


{/* Content ends */}
</React.Fragment>


export default content;
