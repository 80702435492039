import React, { Component } from 'react'
// import styles from './Project.module.css'
import TheArt from './TheArt/TheArt'
import Overlay from '../Overlay/Overlay'
import Explanation from './Explanation/Explanation'
import ListOfProjects from '../../projects/ListOfProjects';
import {withRouter} from 'react-router-dom';

// window.addEventListener('touchstart', function onFirstTouch() {
//   // we could use a class
//   document.body.classList.add('user-is-touching');

//   // or set some global variable
//   window.USER_IS_TOUCHING = true;

//   // or set your app's state however you normally would
//   myFrameworkOfChoice.dispatchEvent('USER_IS_TOUCHING', true);

//   // we only need to know once that a human touched the screen, so we can stop listening now
//   window.removeEventListener('touchstart', onFirstTouch, false);
// }, false);


class Project extends Component {
  
  state = {
    navIsOpen: false,
    currentPath: this.props.location.pathname,
    nextProject: undefined
  }

  componentDidMount() {
    document.body.classList.remove('no-scroll');

    // set nextProject to undefined if it's home

    let nextProject = undefined;

    if (this.state.currentPath !== "/") {
      const currentProject = ListOfProjects.find((p) => {
        return p.path === this.state.currentPath
      })
      nextProject = ListOfProjects.find((p) => {
        return p.position === currentProject.position + 1
      })
    }
    
    this.setState(() => ({ 
      currentPath: this.props.location.pathname,
      nextProject
    }))
  }

  handleToggleNavigation = () => {
    // add class no-scroll to body
    if (!this.state.navIsOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    this.setState((prevState) => ({ 
      navIsOpen: !prevState.navIsOpen,
    }))
  }


  render() {
    const {
      name,
      // className,
      content,
      contentStyles,
      explanation,
      parent,
      isHome
    } = this.props

    return (
      <React.Fragment>
        {!isHome &&
          <TheArt className={contentStyles} content={content}/>
        }
        <Overlay
          handleToggleNavigation={this.handleToggleNavigation}
          headerIsHidden={isHome}
          introIsHidden={!isHome}
          navIsOpen={isHome ? true : this.state.navIsOpen}
          nextProject={this.state.nextProject}
        />
        <Explanation name={name} parent={parent}>{explanation}</Explanation>
      </React.Fragment>
    )
  }
}

// <TheArt className={contentStyles} content={content}/>
// <TheArt content={content}/>
// <div className={this.props.className}>
//   <NavigationOverlay 
//     navIsOpen={this.state.navIsOpen}
//     handleToggleNavigation={this.handleToggleNavigation}
//   />
//   <div className={`${this.state.navIsOpen ? styles.collapseContent : undefined} ${styles.projectContainer}`}>
//     {this.props.children}
//   </div>
// </div>

export default withRouter(Project)
// export default Project