import React from 'react'
import LazyLoad from 'react-lazyload'
import styles from './styles.module.css'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'
import text from './text'
import partitions from './partitions'

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const pad = (n, width, z) => {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const images = {
  total: 861,
  width: 110,
  height: 147
}

// New array with 861 imagenes
const fileNames = new Array(images.total).fill(undefined).map((val,i) => `br-${pad(i+1, 3)}.jpg`)
const allFileNames = [...fileNames, ...fileNames, ...fileNames, ...fileNames, ...fileNames]
const mixNames = shuffle(allFileNames)

let groupTotal = 0
const imagesInPartitions = partitions.map((partition, i) => {
  const partitionArray = partition.map((group, j) => {
    const groupArray = new Array(group).fill(undefined).map((img, z) => {
      const singleElement = z + groupTotal
      return mixNames[singleElement]
    })
    groupTotal += group
    return groupArray
  })
  return partitionArray
})

let imgCount = 1;

const content =

<React.Fragment>
{/* Content starts here */}

<div className={styles.images}>
  {imagesInPartitions.map((partition, i) => {
    const fullPartition = (
      <div 
        className={styles.line}
        key={`${i}`}
      >
      <span className={styles.numbers}>
      {partitions[i].map((n) => `${n}  `)}
      </span>
      {partition.map((group, j) => {
        return (
          <div
            key={`${i}-${j}`}
            className={styles.group}
          >
            {group.map((img, z) => {
              const currentPartitionCount = i%16+1
              const hasCircle = currentPartitionCount === imgCount
              const fullImage = (
                <React.Fragment key={`${z}f`}>
                <LazyLoad
                  offset={500}
                  width={images.width}
                  height={images.height}
                  key={`${i}-${j}-${z}-ll`}
                  placeholder={
                    <Placeholder className={styles.placeholder} width={images.width} height={images.height} />
                  }
                  once={true}
                >
                  <img
                    alt="Portrait" className={hasCircle ? styles.circle : ''}
                    key={`${i}-${j}-${z}`}
                    src={`${process.env.PUBLIC_URL}/project-media/SomeAreSaints/${img}`}
                    width={images.width} height={images.height}
                  />
                </LazyLoad>
                  {hasCircle &&
                    (
                      <div className={styles.circleOutside}>
                        <div className={styles.circleOutsideInside}>
                        </div>
                      </div>
                    )
                  }
                </React.Fragment>
              )
              imgCount = imgCount === 16 ? 1 : imgCount + 1
              return fullImage
            })}
          </div>
        )
      })}
      </div>
    )
    return fullPartition
    
  })}
</div>

<div className={styles.text}>
  <img
    alt="Portrait" className={styles.formula}
    src={`${process.env.PUBLIC_URL}/project-media/SomeAreSaints/generating-function.png`}
    width="232" height="49"
  />
  <p className={styles.formulaCaption}><small>Generating function for partitions of n</small></p>
  <p className={styles.author}><small><em>― Samuel Beckett, Molloy</em></small></p>
  <p>{text}</p>
</div>

{/* Content ends */}
</React.Fragment>


export default content;
