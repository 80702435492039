import React, { Component } from 'react';
import styles from './styles.module.css';
import info from './info';

import content from './content';
import explanation from './explanation';

import Project from '../../components/Project/Project';

const {
  name,
  // slug,
  // path,
  // componentName,
  // category,
  parent,
  // isPublic
} = info;

class DrowningLanguageI extends Component {
  render() {
    return (
      <Project 
        name={name}
        className={styles.projectStyles}
        parent={parent}
        content={content}
        contentStyles={styles.contentStyles}
        explanation={explanation}
        isHome={false}
      />
    );
  }
}

export default DrowningLanguageI;

