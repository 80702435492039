import React from 'react'
import styles from './styles.module.css'
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'

const content =

<React.Fragment>
{/* Content starts here */}
<span className={styles.title}>Composition I</span>
<VimeoVideo
  className={styles.video}
  title="Composition I"
  id="74015728"
  width="600"
  height="330"
/>

<span className={styles.title}>Composition II</span>
<VimeoVideo
  className={styles.video}
  title="Composition II"
  id="74018191"
  width="600"
  height="330"
/>

<span className={styles.title}>Composition III</span>
<VimeoVideo
  className={styles.video}
  title="Composition III"
  id="74015729"
  width="600"
  height="330"
/>

{/* Content ends */}
</React.Fragment>


export default content;
