import React from 'react'
// import styles from './styles.module.css'
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'

const content =

<React.Fragment>
{/* Content starts here */}

  <VimeoVideo
    title="Head Guts Feet"
    id="119653420"
    width="960"
    height="540"
  />

{/* Content ends */}
</React.Fragment>


export default content
