import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>A formal exercise:</p>

<p>The diptych format opposes two images, forces comparison — connects but separates. 
The connected edge becomes the most relevant part. This connection is both formal 
and conceptual.</p>

<p>A contradiction:</p>

<p>The two recordings on each diptych are of the same thing, recorded at the same time.</p>

<p>The two recordings on each diptych are of the same thing, recorded at different times.<br/>
The video on the left was recorded first. The video on the right was recorded first.</p>
<small>Berlin — 2013</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;