import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import styles from './Header.module.css';

// Courrent route
// Next route

class Header extends Component {
  render() {    
    return (
      <header className={`${styles.header} ${this.props.navIsOpen ? styles.headerWithOpenNav : ''}`}>
      <button 
        onClick={this.props.handleToggleNavigation}
        className={`${styles.menuButton} ${this.props.navIsOpen ? styles.isActive : ''}`}
      >
        <h1 className={styles.name}>Guillermo Gineste</h1>
        <span className={styles.label}>Menu</span>
        <div className={styles.icon}></div>
      </button>
      {
        typeof this.props.nextProject !== 'undefined' &&
          <Link className={styles.nextLink} to={`/${this.props.nextProject.slug}`}>
            <span className={styles.nextLabel}>Next Project:</span>
            <span className={styles.nextTitle}>{this.props.nextProject.name}</span>
          </Link>
      }
      
    </header>
    )
  }
}



export default Header;