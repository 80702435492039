import React from 'react'
import LazyLoad from 'react-lazyload'
import styles from './styles.module.css'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'

function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

// const total = 662
const rowsWithLeadingImage = [
  15, 18, 24, 29, 32, 36, 48, 49,
  50, 61, 66, 72, 81, 95, 96, 100,
  101, 104, 107, 111, 115, 129,
  133, 135, 138, 141, 148, 150,
  151, 162, 165, 169, 170, 173,
  174, 182, 186, 195, 196, 199
]
const imagesInRow = [
  1, 10, 20, 24, 33, 41, 44, 46, 49, 51, 53, 55,
  56, 57, 60, 61, 64, 70, 71, 73, 77, 79, 83, 84,
  85, 86, 89, 97, 99, 100, 104, 112, 113, 114, 117,
  119, 120, 131, 135, 136, 140, 152, 153, 155, 157,
  161, 166, 170, 171, 172, 173, 174, 176, 178, 181,
  185, 186, 187, 188, 190, 192, 193, 194, 198, 200,
  201, 202, 204, 207, 209, 215, 218, 219, 220, 223,
  226, 228, 230, 234, 244, 245, 246, 253, 255, 256,
  257, 258, 259, 260, 261, 262, 269, 276, 281, 282,
  283, 284, 285, 290, 297, 298, 299, 308, 312, 313,
  314, 315, 316, 317, 319, 320, 321, 322, 323, 324,
  325, 328, 330, 332, 335, 338, 339, 341, 343, 346,
  348, 349, 350, 351, 352, 354, 356, 357, 358, 362,
  363, 364, 366, 367, 382, 382, 383, 386, 389, 397,
  400, 403, 404, 405, 408, 409, 410, 419, 420, 428,
  433, 441, 449, 450, 453, 457, 465, 466, 476, 477,
  478, 489, 496, 499, 500, 501, 507, 508, 509, 510,
  511, 513, 519, 520, 522, 524, 548, 549, 552, 553,
  554, 555, 556, 563, 569, 571, 572, 573, 574, 575,
  576, 577, 590, 598, 599, 612, 614, 615, 637, 646,
  657
]

const content =

<React.Fragment>
  {
    imagesInRow.map((row, i, arr) => {
      const isLast = i+1 === arr.length
      const firstElement = row
      const lastElement = isLast ? 662 : arr[i+1]
      const hasLeadingImage = rowsWithLeadingImage.includes(i+1)
      let imgArray =
        isLast ? (
          new Array(lastElement - firstElement + 1)
        ):(
          new Array(lastElement - firstElement)
        )
      imgArray = imgArray.fill(undefined).map((val,i) => pad(i+firstElement, 3))
      imgArray = imgArray.map((img, j) => {
        return (
          <LazyLoad
            offset={100}
            width={534}
            height={400}
            placeholder={<Placeholder width={534} height={400} />}
            key={`${i+1}-${j+1}`}
            overflow={true}
            once={true}
          >
            <img
              key={`${i+1}-${j+1}`}
              className={styles.image}
              alt=""
              width={534}
              height={400}
              src={`${process.env.PUBLIC_URL}/project-media/NineTimesAndMore/nine-times-and-more-${img}-by-guillermo-gineste.jpg`}
            />
          </LazyLoad>
        )
      })
      return <div key={i+1} className={hasLeadingImage ? styles.rowWithImage : styles.row}>{imgArray}</div>
      })
  }
</React.Fragment>


export default content