import React from 'react';
import styles from './Intro.module.css';


const Intro = () => (
  <div className={styles.intro}>
    <h1 className={styles.title}>Guillermo Gineste</h1>
    <h2 className={styles.subtitle}>Videoart, photographic projects, drawing, painting and in-betweens.</h2>
  </div>
);

export default Intro;