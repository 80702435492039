import React, { Component } from 'react';
import styles from './VimeoVideo.module.css';

class VimeoVideo extends Component {
  render() {
    const {
      id,
      width,
      height,
      title,
      className
    } = this.props

    const responsiveRatio = ((Math.min(width, height) / Math.max(width, height))) * 100

    return (
      <div
        className={`${styles.video} ${className}`}
        style={{
          paddingBottom: `${responsiveRatio}%`,
        }}
      >
        <iframe
          src={`https://player.vimeo.com/video/${id}`}
          width={width} height={height}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          title={title}
        >
        </iframe>
      </div>
    );
  }
}

export default VimeoVideo;