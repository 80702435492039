import React from 'react';
import './App.css';
// import MainNavigation from './components/MainNavigation/MainNavigation';
// import Header from './components/Header/Header';
// import ColorScale from './components/ColorScale/ColorScale';
import AppRouter from './routers/AppRouter';

// <Header>
// <MainNavigation>
//  <Intro> -> Conditional, only if inside a project
//  <ListOfProjects>
// <Project>
//  <ProjectContent>
//  <ProjectExplanation>

// class App extends Component {
//   render() {
//     return (
//       <div className="App">
//         <ColorScale/>
//         <Header/>
//         <MainNavigation/>
//       </div>
//     )
//   }
// }

const App = () => <AppRouter />;

export default App;
