import React from 'react';
import styles from './styles.module.css';
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'

const content =

<React.Fragment>
{/* Content starts here */}

<div className={styles.videoContainer}>
  <VimeoVideo
    title="Did she walk upright?"
    id="135708314"
    width="960"
    height="360"
  />
</div>

{/* Content ends */}
</React.Fragment>


export default content;
