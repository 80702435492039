import React from 'react'
import LazyLoad from 'react-lazyload'
import styles from './styles.module.css'

const images = {
  total: 9,
  width: 1320,
  height: 993
}

const content =

<React.Fragment>
{/* Content starts here */}

{new Array(images.total).fill(undefined).map((img, i) => {
  return (
    <LazyLoad
      offset={200}
      width={images.width}
      height={images.height}
      key={`${i+1}`}
      once={true}
    >
      <img
        key={`${i+1}`}
        className={styles.image}
        alt=""
        width={images.width}
        height={images.height}
        src={`${process.env.PUBLIC_URL}/project-media/PartioDeMiUnBarcoLlevandome/all-${i+1}.jpg`}
      />
    </LazyLoad>
  )
})}

{/* Content ends */}
</React.Fragment>


export default content;
