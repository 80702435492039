import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


const handleFirstTab = (e) => {
  e.keyCode === 9 && // the "I am a keyboard user" key
    document.body.classList.add('focus-visible');
    window.removeEventListener('keydown', handleFirstTab);
}

window.addEventListener('keydown', handleFirstTab);

ReactDOM.render(<App />, document.getElementById('root'));
