import React from 'react'
import styles from './styles.module.css'
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'
import LazyLoad from 'react-lazyload'

function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const imagesRiver = {
  total: 3,
  width: 569 ,
  height: 320
}

const imagesAnimation = {
  total: 19,
  width: 569 ,
  height: 320
}

const frames = [
  26, 24, 12, 11, 16, 19, 4,
  32, 16, 17, 12, 20, 20, 24,
  3, 10, 18, 10, 28
]

const content =

<React.Fragment>
{/* Content starts here */}

<VimeoVideo
  className={styles.video}
  title="The present moment of a thousand small things"
  id="126671477"
  width="500"
  height="281"
/>

{new Array(imagesRiver.total).fill(undefined).map((val,i) => i+1).map((img, i) => {
  return (
    <div className={styles.singleImageContainer} key={`${i}-c`}>
      <LazyLoad
        offset={500}
        width={imagesRiver.width}
        height={imagesRiver.height}
        placeholder={<Placeholder width={imagesRiver.width} height={imagesRiver.height} />}
        key={`${i+1}`}
        once={true}
      >
        <img
          className={styles.singleImage}
          src={`${process.env.PUBLIC_URL}/project-media/ThePresentMomentOfAThousandSmallThings/river-${img}.gif`}
          width={imagesRiver.width} height={imagesRiver.height}
          alt="River animated gif">
        </img>
      </LazyLoad>
    </div>
  )
})}

{new Array(imagesAnimation.total).fill(undefined).map((val,i) => i+1).map((img, i) => {
  return (
    <div className={styles.singleImageContainer} key={`${i+1}-container`}>
      <span className={styles.framesNumber}>{`00:00:00:${pad(frames[i], 2)}`}</span>
      <LazyLoad
        offset={500}
        width={imagesAnimation.width}
        height={imagesAnimation.height}
        placeholder={<Placeholder width={imagesAnimation.width} height={imagesAnimation.height} />}
        key={`${i+1}`}
        once={true}
      >
        <img
          src={`${process.env.PUBLIC_URL}/project-media/ThePresentMomentOfAThousandSmallThings/anim-${img}.gif`}
          width={imagesAnimation.width} height={imagesAnimation.height}
          alt="Animation gif">
        </img>
      </LazyLoad>
    </div>
  )
})}

{/* Content ends */}
</React.Fragment>


export default content
