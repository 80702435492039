import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

  <p><b>A boat sailed from me carrying me away.</b></p>
  <p>Left and Right: Drawing on a poem</p>
  <p>Center: The last time I painted on a canvas. An old painting covered, with dark and gray colors. 
  Summer sunlight in our studio in Hochstraße.</p>
  <p>Over: Drawings from screenshots, pictures and video frames.</p>
  <small>Berlin — 2016</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;