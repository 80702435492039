import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>A Spanish alphabet on twenty-seven paintings and their color.</p>

<blockquote><p><em>a, b, c, d, e, f, g, h, i, j, k, l, m, n, ñ, o, 
p, q, r, s, t, u, v, w, x, y, z</em></p></blockquote>


<p>In the left there is a three by three sequence of oil color scales. From 
left to right an image from a classical painting reveals itself while going 
farther from its own colors.</p>

<p>The title and theme of each original painting is not relevant, neither 
the authors. They represent landscape, architecture, portrait or genre scenes, 
from known or anonymous painters.</p>

<p>As I manipulate and de-construct the original painting, it becomes something 
else, the image of an image, or the image of an image of an image. Then, 
what is the current relationship between the author and his image?</p>

<p>With the use of color, composition and texture we create images 
using them as an expressive language. This work is a reduction of those 
elements to a minimum, a unity, the letter. This unity would allow the 
creation of words and sentences.</p>

<p>There are two versions of this work: what you see here, reproductions 
of the color scales with black and white images of paintings. And a 
physical version with the original oil painted scales and the printed 
versions of the black and white series.</p>

<p><small>Berlin — 2013</small></p>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;