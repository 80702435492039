import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>ella se desnuda en el paraíso<br/>
de su memoria<br/>
ella desconoce el feroz destino<br/>
de sus visiones<br/>
ella tiene miedo de no saber nombrar<br/>
lo que no existe<br/>
</p>
<p><small><em>― Alejandra Pizarnik, Árbol de Diana</em></small></p>
<br/>
<p><em>she undresses in the paradise<br/>
of her memory<br/>
she is unaware of the fierce fate<br/>
of her visions<br/>
she fears not knowing how to name<br/>
what does not exist</em></p>
<p><small><em>― Alejandra Pizarnik, Diana's Tree</em></small></p>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;