import React from 'react';
import styles from './styles.module.css';
import LazyLoad from 'react-lazyload'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'

function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const images = {
  sets: 5,
  total: 25,
  height: 647,
  width: 400
}

const content =

<React.Fragment>
{/* Content starts here */}

  <div className={styles.video}>
    <iframe
      src={`https://player.vimeo.com/video/118840510`}
      width="400" height="647"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen={true}
      title="The Quiet Two"
    >
    </iframe>
  </div>
  <LazyLoad
    offset={500}
    width={images.width}
    height={images.height}
    placeholder={<Placeholder width={images.width} height={images.height} />}
    once={true}
  >
    <img
      className={styles.timeline}
      alt=""
      width="650"
      height="110"
      src={`${process.env.PUBLIC_URL}/project-media/TheQuietTwo/timeline.png`}
    />
  </LazyLoad>

  {new Array(images.sets).fill(undefined).map((val,i) => i+1).map((set, j) => {
    return (
      <div className={styles.set} key={`${j+1}`}>
      <span className={styles.setNumber}>{set}</span>
      {
        new Array(images.total).fill(undefined).map((val,i) => pad(i, 2)).map((img, i) => {
          return (
              <LazyLoad
                offset={500}
                width={images.width}
                height={images.height}
                placeholder={<Placeholder width={images.width} height={images.height} />}
                key={`${i+1}`}
                once={true}
              >
                <img
                  key={`${i+1}`}
                  className={styles.image}
                  alt=""
                  width={images.width}
                  height={images.height}
                  src={`${process.env.PUBLIC_URL}/project-media/TheQuietTwo/${set}_${img}.png`}
                />
              </LazyLoad>
            )
        })
      }
      </div>
    )
  })}

{/* Content ends */}
</React.Fragment>


export default content;
