import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

  <p></p>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;