import React, { Component } from 'react';
import styles from './List.module.css';
import ProjectTitle from './ProjectTitle/ProjectTitle';
import ListOfProjects from '../../../projects/ListOfProjects';

// TODO: allow manual lists that takes a list as child content

class List extends Component {
  render() {
    const {
      title,
      category,
      list
    } = this.props
    
    const hasCustomList = typeof list !== 'undefined'

    let currentList = [];

    if (hasCustomList) {
      // custom list given
      currentList = list;
    } else {
      // no list given
      ListOfProjects
        .filter(project => category === project.category && project.isPublic)
        .sort((a,b) => {
            if (a.position < b.position)
              return -1;
            if (a.position > b.position)
              return 1;
            return 0;
          })
        .forEach((project, i) => {
            if (project.parent) {
              const parentExists = currentList.find((p) => {
                return p.parentName === project.parent
              }) !== undefined;
              if(parentExists) {
                const parentIndex = currentList.findIndex(p => p.parentName === project.parent)
                currentList[parentIndex].childrenProjects.push(project)
              } else {
                currentList.push({
                  "parentName": project.parent,
                  "childrenProjects": [project]
                })
              }
          } else {
            currentList.push(project)
          }
        })
    }

    // console.log(currentList)

    return (
      <section className={styles.listSection}>
        <h3 className={styles.title}>{title}</h3>
        <ul className={styles.list}>
          {currentList.map((project, i) => {
            const isParent = project.hasOwnProperty("parentName");
            if (isParent) {
              return (
                <li key={i}>
                  <ProjectTitle title={project.parentName} isParent={true} />
                  <ul className={styles.subList}>
                    {project.childrenProjects.map((subProject, j) => {
                      return (
                        <li key={`${i}-${j}`}>
                          <ProjectTitle 
                            title={subProject.name}
                            slug={subProject.slug}
                            component={subProject.componentName}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            }
            return (
              <li key={i}>
                <ProjectTitle 
                  title={project.name}
                  slug={project.slug}
                  component={project.componentName}
                />
              </li>
            )
          })}
        </ul>
      </section>
    )
  }
}


export default List;