import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>The space between us.<br/>
The space between objects.</p>
<p>The boundary between an object and another.<br/>
The boundary between one person and another.</p>
<p>How to find it.</p>
<p>How to look for it.</p>

<small>Berlin — 2013</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;