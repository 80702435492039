import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>Three ways of understanding.</p>
<p>thinking head<br></br>
thinking guts<br></br>
thinking feet</p>
<br></br>
<br></br>
<p>"I too love everything that flows: rivers, sewers, lava, semen, blood, bile, words, sentences. 
I love the amniotic fluid when it spills out of the bag. I love the kidney with its painful 
gallstones, its gravel and what-not; I love the urine that pours out scalding and the clap 
that runs endlessly; I love the words of hysterics and the sentences that flow on like 
dysentery and mirror all the sick images of the soul; I love the great rivers like the Amazon 
and the Orinoco, where crazy men like Moravagine float on through dream and legend in an open 
boat and drown in the blind mouths of the river. I love everything that flows, even the menstrual 
flow that carries away the seed unfecund. I love scripts that flow, be they hieratic, esoteric, 
perverse, polymorph, or unilateral. I love everything that flows, everything that has time in it 
and becoming, that brings us back to the beginning where there is never end: the violence of the 
prophets, the obscenity that is ecstasy, the wisdom of the fanatic, the priest with his rubber 
litany, the foul words of the whore, the spittle that floats away in the gutter, the milk of the 
breast and the bitter honey that pours from the womb, all that is fluid, melting, dissolute and 
dissolvent, all the pus and dirt that in flowing is purified, that loses its sense of origin, 
that makes the great circuit toward death and dissolution. The great incestuous wish is to flow on, 
one with time, to merge the great image of the beyond with the here and now. A fatuous, suicidal 
wish that is constipated by words and paralyzed by thought."</p>
<p>
  <small><em>― Henry Miller, Tropic of Cancer</em></small>
</p>

<small>Berlin — 2012</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;