
import Boundary from './Boundary'
import DidSheWalkUpright from './DidSheWalkUpright'
import Diptychs from './Diptychs'
import DrowningLanguageI from './DrowningLanguageI'
import DrowningLanguageIi from './DrowningLanguageIi'
import DrowningLanguageIii from './DrowningLanguageIii'
import DrowningLanguageIv from './DrowningLanguageIv'
import DrowningLanguageV from './DrowningLanguageV'
import DrowningLanguageVi from './DrowningLanguageVi'
import HeadGutsFeet from './HeadGutsFeet'
import ItIsRightHereI from './ItIsRightHereI'
import ItIsRightHereIi from './ItIsRightHereIi'
import NakedInParadise from './NakedInParadise'
import NineTimesAndMore from './NineTimesAndMore'
import PartioDeMiUnBarcoLlevandome from './PartioDeMiUnBarcoLlevandome'
import QualityIsEverything from './QualityIsEverything'
import RFromLight from './RFromLight'
import SomeAreSaints from './SomeAreSaints'
import ThePresentMomentOfAThousandSmallThings from './ThePresentMomentOfAThousandSmallThings'
import TheQuietTwo from './TheQuietTwo'
import TwentySevenPaintings from './TwentySevenPaintings'
import TwoLinesDontConverge from './TwoLinesDontConverge'
import VaseOfFlowers from './VaseOfFlowers'
import YouAreNotThereIAm from './YouAreNotThereIAm'

const projects = [
{"name":"Boundary","slug":"boundary","path":"/boundary","componentName":"Boundary","position": 19, "component": Boundary,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Did she walk upright?","slug":"did-she-walk-upright","path":"/did-she-walk-upright","componentName":"DidSheWalkUpright","position": 12, "component": DidSheWalkUpright,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Diptychs","slug":"diptychs","path":"/diptychs","componentName":"Diptychs","position": 18, "component": Diptychs,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Drowning Language I","slug":"drowning-language-i","path":"/drowning-language-i","componentName":"DrowningLanguageI","position": 4, "component": DrowningLanguageI,
  "category":"Projects","parent":"Drowning Language","isPublic":true},
{"name":"Drowning Language II","slug":"drowning-language-ii","path":"/drowning-language-ii","componentName":"DrowningLanguageIi","position": 5, "component": DrowningLanguageIi,
  "category":"Projects","parent":"Drowning Language","isPublic":true},
{"name":"Drowning Language III","slug":"drowning-language-iii","path":"/drowning-language-iii","componentName":"DrowningLanguageIii","position": 6, "component": DrowningLanguageIii,
  "category":"Projects","parent":"Drowning Language","isPublic":true},
{"name":"Drowning Language IV","slug":"drowning-language-iv","path":"/drowning-language-iv","componentName":"DrowningLanguageIv","position": 7, "component": DrowningLanguageIv,
  "category":"Projects","parent":"Drowning Language","isPublic":true},
{"name":"Drowning Language V","slug":"drowning-language-v","path":"/drowning-language-v","componentName":"DrowningLanguageV","position": 8, "component": DrowningLanguageV,
  "category":"Projects","parent":"Drowning Language","isPublic":true},
{"name":"Drowning Language VI","slug":"drowning-language-vi","path":"/drowning-language-vi","componentName":"DrowningLanguageVi","position": 9, "component": DrowningLanguageVi,
  "category":"Projects","parent":"Drowning Language","isPublic":true},
{"name":"Head Guts Feet","slug":"head-guts-feet","path":"/head-guts-feet","componentName":"HeadGutsFeet","position": 20, "component": HeadGutsFeet,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"It is right here I","slug":"it-is-right-here-i","path":"/it-is-right-here-i","componentName":"ItIsRightHereI","position": 14, "component": ItIsRightHereI,
  "category":"Projects","parent":"Perspective Lessons","isPublic":true},
{"name":"It is right here II","slug":"it-is-right-here-ii","path":"/it-is-right-here-ii","componentName":"ItIsRightHereIi","position": 15, "component": ItIsRightHereIi,
  "category":"Projects","parent":"Perspective Lessons","isPublic":true},
{"name":"Naked in Paradise","slug":"naked-in-paradise","path":"/naked-in-paradise","componentName":"NakedInParadise","position": 3, "component": NakedInParadise,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Nine Times And More","slug":"nine-times-and-more","path":"/nine-times-and-more","componentName":"NineTimesAndMore","position": 17, "component": NineTimesAndMore,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Partió de mí un barco llevándome","slug":"partio-de-mi-un-barco-llevandome","path":"/partio-de-mi-un-barco-llevandome","componentName":"PartioDeMiUnBarcoLlevandome","position": 10, "component": PartioDeMiUnBarcoLlevandome,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Quality is everything","slug":"quality-is-everything","path":"/quality-is-everything","componentName":"QualityIsEverything","position": 0, "component": QualityIsEverything,
  "category":"Projects","parent":false,"isPublic":false},
{"name":"R from: Light","slug":"r-from-light","path":"/r-from-light","componentName":"RFromLight","position": 23, "component": RFromLight,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Some Are Saints","slug":"some-are-saints","path":"/some-are-saints","componentName":"SomeAreSaints","position": 2, "component": SomeAreSaints,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"The present moment of a thousand small things","slug":"the-present-moment-of-a-thousand-small-things","path":"/the-present-moment-of-a-thousand-small-things","componentName":"ThePresentMomentOfAThousandSmallThings","position": 11, "component": ThePresentMomentOfAThousandSmallThings,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"The Quiet Two","slug":"the-quiet-two","path":"/the-quiet-two","componentName":"TheQuietTwo","position": 13, "component": TheQuietTwo,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Twenty-Seven Paintings","slug":"twenty-seven-paintings","path":"/twenty-seven-paintings","componentName":"TwentySevenPaintings","position": 22, "component": TwentySevenPaintings,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"Two Lines Don't Converge","slug":"two-lines-dont-converge","path":"/two-lines-dont-converge","componentName":"TwoLinesDontConverge","position": 16, "component": TwoLinesDontConverge,
  "category":"Projects","parent":"Perspective Lessons","isPublic":true},
{"name":"Vase of Flowers","slug":"vase-of-flowers","path":"/vase-of-flowers","componentName":"VaseOfFlowers","position": 21, "component": VaseOfFlowers,
  "category":"Projects","parent":false,"isPublic":true},
{"name":"You are not there, I am","slug":"you-are-not-there-i-am","path":"/you-are-not-there-i-am","componentName":"YouAreNotThereIAm","position": 1, "component": YouAreNotThereIAm,
  "category":"Projects","parent":false,"isPublic":true}
]

export default projects
