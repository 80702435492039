import React from 'react';
import styles from './styles.module.css';
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'

const content =

<React.Fragment>
{/* Content starts here */}

<VimeoVideo
  className={styles.video}
  title="You are not there, I am"
  id="184231153"
  width="640"
  height="216"
/>

<div className={styles.arboles}>
  <img
    alt="" className={styles.arbol}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/arbol-1.jpg`}
  />
  <img
    alt="" className={styles.arbol}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/arbol-2.jpg`}
  />
  <img
    alt="" className={styles.arbol}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/arbol-3.jpg`}
  />
  <img
    alt="" className={styles.arbol}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/arbol-4.jpg`}
  />
</div>

<div className={styles.row}>
  <img
    alt="" className={styles.plant}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/1.jpg`}
  />
</div>

<div className={styles.row}>
  <img
    alt="" className={styles.rastro}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/5.jpg`}
  />
  <img
    alt="" className={styles.secondPlant}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/4.jpg`}
  />
</div>

<div className={styles.row}>
  <img
    alt="" className={styles.thirdPlant}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/2.jpg`}
  />
  <img
    alt="" className={styles.plaza}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/6.jpg`}
  />
</div>

<div className={styles.row}>
  <img
    alt="" className={styles.gloves}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/3.jpg`}
  />
</div>

<div className={styles.row}>
  <img
    alt="" className={styles.light}
    src={`${process.env.PUBLIC_URL}/project-media/YouAreNotThereIAm/7.jpg`}
  />
</div>


{/* Content ends */}
</React.Fragment>


export default content;
