import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

  <p>Unraveling just makes it more complicated, there is less to see and less to imitate.</p>
  <small>Berlin — 2014</small>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;