import React from 'react';
import styles from './styles.module.css';
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'

const videoIDs = [
  "142175714",
  "142175715",
  "142175716",
  "142175713",
  "142175709",
  "142268916",
  "142268916",
  "142175707",
  "142175706",
  "142371073"
]

const content =

<React.Fragment>
{/* Content starts here */}
  {videoIDs.map((id, i) => {
    return (
      <VimeoVideo
        key={`${id}-${i}`}
        className={styles.video}
        title={`Two Lines Don't Converge - ${i+1}`}
        id={id}
        width="720"
        height="720"
      />
    )
  })}
  

{/* Content ends */}
</React.Fragment>


export default content;