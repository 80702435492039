import React from 'react';
import styles from './styles.module.css'
import VimeoVideo from '../../components/VimeoVideo/VimeoVideo'

const content =

<React.Fragment>
{/* Content starts here */}

<span className={styles.title}>Diptych 01</span>
<VimeoVideo
  className={styles.video}
  title="Diptych 01"
  id="104664606"
  width="960"
  height="270"
/>

<span className={styles.title}>Diptych 02</span>
<VimeoVideo
  className={styles.video}
  title="Diptych 02"
  id="105157254"
  width="960"
  height="270"
/>

<span className={styles.title}>Diptych 03</span>
<VimeoVideo
  className={styles.video}
  title="Diptych 03"
  id="105795869"
  width="960"
  height="270"
/>

{/* Content ends */}
</React.Fragment>


export default content;
