import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

  <p>Antes del abrazo, una mujer, antes del Mal</p>
  <p>"Was she walking up to Eve? Was she walking at all?"</p>
  <small>Berlin — 2016</small>


{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;