import React from 'react'
import LazyLoad from 'react-lazyload'
import styles from './styles.module.css'
import Placeholder from '../../components/ImagePlaceholder/ImagePlaceholder'

function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const images = {
  total: 86,
  width: 436,
  height: 600
}

const content =

<React.Fragment>
{/* Content starts here */}

  {new Array(images.total).fill(undefined).map((val,i) => pad(i+1, 2)).map((img, i) => {
    return (
      <LazyLoad
            offset={500}
            width={images.width}
            height={images.height}
            placeholder={<Placeholder width={images.width} height={images.height} />}
            key={`${i+1}`}
            once={true}
          >
            <img
              key={`${i+1}`}
              className={styles.image}
              alt=""
              width={images.width}
              height={images.height}
              src={`${process.env.PUBLIC_URL}/project-media/ItIsRightHereIi/red-line-${img}.jpg`}
            />
          </LazyLoad>
    )
  })}

{/* Content ends */}
</React.Fragment>


export default content;
