import React, { Component } from 'react';
import Project from '../../components/Project/Project';

class Home extends Component {
  render() {
    return (
        <Project
        isHome={true}
        />
    );
  }
}

export default Home;