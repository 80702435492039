import React from 'react';

const projectExplanation =

<React.Fragment>
{/* Explanation starts here */}

<p>Series of pictures from neighbours laundry, 
they were taken betwen Monday, December 19th, 2011 and 
Tuesday, September 25th, 2012.<br/>
I never met these neigbours.</p>

<p>During the 10 months I took other pictures with the same camera,
the numbers in empty spaces are the numbers in the filename
of those pictures.</p>

<p><small>Scroll right to see the full set</small></p>

{/* Explanation ends */}
</React.Fragment>

export default projectExplanation;